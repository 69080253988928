import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";

class Page extends Component {
    render() {
      // console.log(this.props);
      const blocks = buildBlocks(this.props.data.wpPage.blocks, {}, {}, this.props.data.wpPage.id);
      return (
        <Layout
          meta={{
            ...this.props.data.wpPage.Meta,
            canonical: this.props.data.wpPage.uri
          }}
          path={this.props.pageContext.pagePath}
          cta={this.props.data.wpPage.PageCTA}
          themeOptions={this.props.data.wp.themeOptions}
        >
            {blocks}
        </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: {eq: $id}) {
      id
      slug
      title
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }   
      ... on WpBlockEditorContentNode {
        blocks {
          ...CoreBlock
          ...CoreFields
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page
